import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <p><strong parentName="p">{`Usable with CoreMedia Content Cloud - Service`}</strong></p>
    <hr></hr>
    <p>{`The CoreMedia Ingest Service is a cloud only hosted service enabling you to develop your own importer-like applications
for the CoreMedia Content Cloud.`}</p>
    <p>{`It offers an API with basic methods to create and modify content items. In addition, the content lifecycle is supported
with bulk publication, unpublication and deletion methods.`}</p>
    <h2>{`Use Cases`}</h2>
    <ul>
      <li parentName="ul">{`Use the Ingest Service for content import into your hosted CoreMedia system.`}</li>
      <li parentName="ul">{`Use the Ingest Service to publish, withdraw and delete content.`}</li>
      <li parentName="ul">{`Use the Ingest Service to modify content.`}</li>
    </ul>
    <h2>{`Getting Access to the Ingest Service`}</h2>
    <p>{`Contact the CoreMedia support at `}<a parentName="p" {...{
        "href": "mailto:support@coremediaoncloud.com"
      }}>{`support@coremediaoncloud.com`}</a>{` to get the Ingest Service activated for your account.`}</p>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Find the Ingest Service API documentation at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/services/ingest-service/ingest-service-api/"
        }}>{`API Reference`}</a>{`.`}</li>
      <li parentName="ul">{`Find best practices for the usage of the Ingest Service at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/services/ingest-service/ingest-service/"
        }}>{`Working with Ingest Service`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      